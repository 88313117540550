import React from 'react'
import ReusableCard from './Cards'
import B1 from '../../assets/1B.png'
import B2 from '../../assets/2B.png'
import one from '../../assets/Chess.svg'
import second from '../../assets/1V1.png'
import ninth from '../../assets/ninth.png'
import tenth from '../../assets/tenth.png'
import eleventh from '../../assets/eleventh.png'
import twelth from '../../assets/twelth.png'
import third from '../../assets/multiplayer 1.png'
import forth from '../../assets/Dares.png'
import fifth from '../../assets/Dare in X.png'
import sixth from '../../assets/catoff.png'
import sev from '../../assets/Side bet.png'
import eig from '../../assets/valorant.jpg'
import thirteen from '../../assets/thirteen.png'

function Sec2_Blinks() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 md:m-[280px] md:mt-0 m-[20px] mt-0 mb-2">
      <ReusableCard
        imageSrc={thirteen}
        text="Two Truths And A Lie"
        buttonLabel="Expose the Lie!"
        disabled={false}
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Ftwotruthandalie.catoff.xyz%2Fapi%2Factions%2Fcreate-tnlGame&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={eig}
        text="Valorant"
        buttonLabel="Take Your Shot"
        disabled={false}
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fvalorant.catoff.xyz%2Fapi%2Factions%2Fcreate-valorant-challenge%3Fclusterurl%3Dmainnet&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={eleventh}
        text="Pollmeister"
        buttonLabel="Cast your Vote"
        disabled={false}
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fvote.catoff.xyz%2Fapi%2Factions%2Fcreate-poll%3Fclusterurl%3Dmainnet&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={tenth}
        text="Never Have I Ever"
        buttonLabel="Play Now"
        disabled={false}
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fneverhaveiever.catoff.xyz%2Fapi%2Factions%2Fcreate-never-have-i-ever%3Fclusterurl%3Dmainnet&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={forth}
        text="Insta Dares"
        buttonLabel="Dare to Post"
        onClick={() =>
          (window.location.href =
            ' https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Finstadares.catoff.xyz%2Fapi%2Factions%2Fcreate-instaDare%3Fclusterurl%3Dmainnet&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={fifth}
        text="X Dares"
        buttonLabel="Dare to Tweet"
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fxdares.catoff.xyz%2Fapi%2Factions%2Fcreate-x-dares%3Fclusterurl%3Dmainnet&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={ninth}
        text="Rock, Paper, Scissors"
        buttonLabel="Make your Call"
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action:https://rps.catoff.xyz/api/actions/create-rock-paper-scissors?clusterurl=mainnet&cluster=mainnet')
        }
      />

      {/* <ReusableCard
        imageSrc={one}
        text="1v1 Chess"
        buttonLabel="Make Your Move"
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action:https://chess.catoff.xyz/api/actions/form')
        }
      /> */}

      <ReusableCard
        imageSrc={second}
        text="1v1 Challenge"
        buttonLabel="Start the Duel"
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fjoin.catoff.xyz%2Fapi%2Factions%2Fcreate-challenge%3Fclusterurl%3Dmainnet%26participationtype%3D1&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={third}
        text="Multiplayer Challenge"
        buttonLabel="Start the Battle"
        onClick={() =>
          (window.location.href =
            ' https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fjoin.catoff.xyz%2Fapi%2Factions%2Fcreate-challenge%3Fclusterurl%3Dmainnet%26participationtype%3D2&cluster=mainnet')
        }
      />

      <ReusableCard
        imageSrc={forth}
        text="Create Dares"
        buttonLabel="Make a Dare"
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fjoin.catoff.xyz%2Fapi%2Factions%2Fcreate-challenge%3Fclusterurl%3Dmainnet%26participationtype%3D0&cluster=mainnet')
        }
      />

      <ReusableCard
        imageSrc={sixth}
        text="Join Challenges"
        buttonLabel="Join the Fun"
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fjoin.catoff.xyz%2Fapi%2Factions%2Fjoin-challenge%3Fclusterurl%3Dmainnet&cluster=mainnet')
        }
      />

      <ReusableCard
        imageSrc={twelth}
        text="Sporcle Quizzes"
        buttonLabel="Coming Soon"
        disabled={true}
        onClick={() =>
          (window.location.href =
            'https://dial.to/?action=solana-action%3Ahttps%3A%2F%2Fxdares.catoff.xyz%2Fapi%2Factions%2Fcreate&cluster=mainnet')
        }
      />
      <ReusableCard
        imageSrc={sev}
        text="Side Bet Challenges"
        buttonLabel="Coming Soon"
        disabled={true}
        onClick={() => (window.location.href = 'https://sidebet.catoff.xyz')}
      />
    </div>
  )
}

export default Sec2_Blinks
