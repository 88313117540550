import React, { useRef, useState, useEffect } from 'react'
import CanvasDraw from 'react-canvas-draw'

function Skribble() {
  const canvasRef = useRef(null)
  const [brushColor, setBrushColor] = useState('#000000')
  const [brushRadius, setBrushRadius] = useState(4)
  const [timer, setTimer] = useState(120) // 2 minutes in seconds
  const [showPopup, setShowPopup] = useState(false)
  const [snapshot, setSnapshot] = useState(null)

  // Countdown Timer Logic
  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
    } else {
      // Timer expired, take snapshot
      handleSnapshot()
    }
  }, [timer])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`
  }

  const handleClear = () => {
    canvasRef.current.clear()
  }

  const handleUndo = () => {
    canvasRef.current.undo()
  }

  const handleSnapshot = () => {
    const dataUrl = canvasRef.current.canvas.drawing.toDataURL('image/png')
    setSnapshot(dataUrl)
    setShowPopup(true)
  }

  const handleSave = () => {
    const dataUrl = canvasRef.current.canvas.drawing.toDataURL('image/png')
    const link = document.createElement('a')
    link.href = dataUrl
    link.download = 'skribble.png'
    link.click()
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex items-center justify-between shadow-md py-4 px-8">
        <h1 className="text-2xl font-bold text-blue-600">
          Skribble Blinks Catoff
        </h1>
        <span className="text-lg font-medium text-gray-700">
          Time Left: {formatTime(timer)}
        </span>
      </div>

      <div className="flex flex-1">
        <div className="w-1/5 bg-white shadow-lg p-8 flex flex-col items-start space-y-4">
          <h2 className="text-lg font-bold text-gray-700">Tools</h2>

          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Brush Color
            </label>
            <input
              type="color"
              value={brushColor}
              onChange={(e) => setBrushColor(e.target.value)}
              className="w-full h-10 border rounded"
            />
          </div>

          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Brush Size
            </label>
            <input
              type="range"
              min="1"
              max="20"
              value={brushRadius}
              onChange={(e) => setBrushRadius(parseInt(e.target.value, 10))}
              className="w-full"
            />
            <span className="text-sm text-gray-500">Size: {brushRadius}</span>
          </div>

          <div className="flex flex-col w-full space-y-2">
            <button
              onClick={handleUndo}
              className="w-full px-4 py-2 bg-yellow-500 text-white font-medium rounded hover:bg-yellow-400 transition"
            >
              Undo
            </button>
            <button
              onClick={handleClear}
              className="w-full px-4 py-2 bg-red-500 text-white font-medium rounded hover:bg-red-400 transition"
            >
              Clear
            </button>
            <button
              onClick={handleSnapshot}
              className="w-full px-4 py-2 bg-green-500 text-white font-medium rounded hover:bg-green-400 transition"
            >
              Snapshot
            </button>
            <button
              onClick={handleSave}
              className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded hover:bg-blue-400 transition"
            >
              Save
            </button>
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center">
          <div className="border-4 border-dashed border-gray-300 bg-white shadow-lg rounded-lg p-4">
            <CanvasDraw
              ref={canvasRef}
              canvasWidth={800}
              canvasHeight={500}
              brushRadius={brushRadius}
              brushColor={brushColor}
              hideGrid
              className="rounded-lg"
            />
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <h2 className="text-lg font-bold text-gray-700 mb-4">
              Time's up! Your snapshot is ready.
            </h2>
            <img
              src={snapshot}
              alt="Snapshot"
              className="border border-gray-300 rounded mb-4"
            />
            <button
              onClick={() => setShowPopup(false)}
              className="px-4 py-2 bg-blue-500 text-white font-medium rounded hover:bg-blue-400 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Skribble
